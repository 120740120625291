import React from 'react'
import { ReactTypeformEmbed } from 'react-typeform-embed';

import Layout from '../components/Layout';

const FormPage = (props) => {
  return <ReactTypeformEmbed url="https://oxfordsummercourses.typeform.com/to/Z1QYfN" />;
}

export default () => (
  <Layout>
    <FormPage />
  </Layout>
);
